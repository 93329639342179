import React from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter
} from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./scss/main.scss";

const rootElement = document.getElementById("root");
const application = (
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
ReactDOM.render(application, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
