import React from "react";
import {
    Container,
    Nav,
    Navbar
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";

type NavbarLinkProps = {
    to: string,
    title: string
}
function NavbarLink(props: NavbarLinkProps) {
    return (
        <li className="nav-item active">
            <Link className="nav-link" to={ props.to }>{ props.title }</Link>
        </li>
    );
}

function UrenlijstNavbar() {
    return (
        <React.Fragment>
            <div style={{ height: "56px" }}/>
            <Navbar fixed="top" variant="light" bg="light">
                <Container>
                    <Navbar.Brand href="/">
                        Urenlijst App
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home"/>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default React.memo(UrenlijstNavbar);
