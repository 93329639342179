import React from "react";
import MetaTags from "@zandor300/react-meta-tags";

type HelmetProps = {
  title?: string | undefined,
  siteName?: string,
  description?: string | undefined,
  slogan?: string | undefined,
  image?: string | undefined
}

function Helmet({
    title = undefined,
    siteName = "Urenlijst App",
    description = undefined,
    slogan = undefined,
    image = undefined
}: HelmetProps) {
    let pageTitle;
    if(title !== undefined) {
        pageTitle = title + " - " + siteName;
    } else if(slogan !== undefined) {
        pageTitle = siteName + " - " + slogan;
    } else {
        pageTitle = siteName
    }
    return (
        <MetaTags>
            <title>{ pageTitle }</title>
            <meta name="title" content={ pageTitle } />
            <meta property="og:title" content={ pageTitle } />
            <meta name="twitter:title" content={ pageTitle } />
            <meta name="description" content={ description } />
            <meta property="og:description" content={ description } />
            <meta name="twitter:description" content={ description } />
            <meta name="summary" content={ description } />
            <meta property="og:image" content={ image } />
            <meta name="twitter:image" content={ image } />
            <meta name="og:site_name" content={ siteName }/>
        </MetaTags>
    )
}

export default React.memo(Helmet);
