import React from "react";
import {
    Container
} from "react-bootstrap";
import Helmet from "../components/Helmet";

import appIcon from "../img/urenlijst-app-icon.png";
import appStore from "../img/appstore.svg";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <Container className="pt-5 pb-5">
                <img src={ appIcon } style={{ maxWidth: "100px", borderRadius: "21%" }} alt="Urenlijst App Icon"/>
                <h1 className="mt-3">Urenlijst App</h1>
                <p style={{ fontSize: "1.2rem" }}>
                    Dit is een app voor werknemers van bedrijven om hun gewerkte uren door te geven aan hun bedrijf. Het bedrijf moet wel geregistreerd staan bij deze app voordat werknemers hun uren door kunnen geven.
                </p>
                <div>
                    <a href="https://apps.apple.com/nl/app/urenlijst/id1373212504" target="_blank" rel="noopener noreferrer">
                        <img src={ appStore } height="60" alt="App Store badge"/>
                    </a>
                </div>
            </Container>
            <Container className="pt-5 pb-5">
                <h2>Functies</h2>
                <ul style={{ fontSize: "1.2rem" }}>
                    <li>Geef uren door aan je werkgever. Uren die je invult zijn direct in te zien door je werkgever.</li>
                    <li>Kijk terug naar in het verleden ingevulde uren. Jij en je werkgever krijgen dezelfde informatie te zien. Daardoor is jullie administratie altijd synchroon.</li>
                    <li>Push notificaties. Krijg wekelijks meldingen als je nog geen uren doorgegeven had. Deze kun je ook per dag aan en uit zetten.</li>
                </ul>
            </Container>
        </React.Fragment>
    );
}

export default React.memo(Home);
