import React from "react";
import {
    Switch,
    Redirect,
    Route,
    useRouteMatch
} from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";

import CookieConsentBanner from "./components/CookieConsentBanner";
import UrenlijstNavbar from "./components/UrenlijstNavbar";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Privacyverklaring from "./pages/Privacyverklaring";
import Page404 from "./pages/Page404";

function App() {
    // useTracking("UA-69123742-7");

    const cleanMatch = useRouteMatch("/privacyverklaring/clean");
    const clean = cleanMatch !== null;

    return (
        <React.Fragment>
            { !clean && (
                <UrenlijstNavbar/>
            )}

            <ScrollToTop/>

            <Switch>
                <Route path="/" component={Home} exact/>
                <Route path="/privacyverklaring">
                    <Privacyverklaring clean={ clean }/>
                </Route>

                <Route path={ ["/index", "/index.html", "/index.htm", "/index.php"] }>
                    <Redirect to="/"/>
                </Route>

                <Route path="/" component={Page404}/>
            </Switch>

            { !clean && (
                <CookieConsentBanner/>
            )}
            { !clean && (
                <Footer/>
            )}
        </React.Fragment>
    );
}

export default App;
