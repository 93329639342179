import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Container
} from "react-bootstrap";
import PageJumbotron from "../components/PageJumbotron";
import Helmet from "../components/Helmet";

function Page404() {
    return (
        <React.Fragment>
            <Helmet title="404 Not Found"/>
            <PageJumbotron title="404 Not Found"/>
            <Container className="pl-md-3 pr-md-3 text-center">
                <p style={{ fontSize: "1.2rem" }}>
                    The page you are trying to visit doesn't exist.{" "}
                    <Link to="/">Go home?</Link>
                </p>
            </Container>
        </React.Fragment>
    );
}

export default React.memo(Page404);
